import { axiosPrivate } from 'api/axios'
import { errorHandling } from 'configs/error.config'
import { toast } from 'react-toastify'
import { ICatalog, IOrder, IProduct, IShop, IShopGetItems } from 'shared/index'

const getCatalogUrl = (url: string) => `/category/${url}`

export const CatalogService = {
	async getAll(): Promise<ICatalog[]> {
		try {
			const { data } = await axiosPrivate.get<ICatalog[]>(
				`categorynew/Прайс-лист/list`
			)

			return data
				.filter((item) => item.id < 30)
				.filter((item) => {
					return item.categoryViews.filter((item2) => {
						return item2.count > 0
					})
				})
		} catch (error) {
			errorHandling(error)
			return error
		}
	},

	async getCoefficient(): Promise<number> {
		try {
			const { data } = await axiosPrivate.get<number>(
				'/catalog/get_coefficient'
			)
			return data
		} catch (error) {
			errorHandling(error)
			return error
		}
	},

	async setCoefficient(coefficient: number): Promise<void> {
		try {
			await axiosPrivate.patch(
				getCatalogUrl('set_coefficient'),
				JSON.stringify(coefficient),
				{
					headers: {
						'Content-Type': 'application/json',
					},
				}
			)
			toast.success('Коэффициент изменен')
		} catch (error) {
			errorHandling(error)
		}
	},

	async setPriority(goodId: number, coefficient: number): Promise<void> {
		try {
			await axiosPrivate.patch(
				getCatalogUrl(`good/${goodId}/setpriority`),
				JSON.stringify(coefficient),
				{
					headers: {
						'Content-Type': 'application/json',
					},
				}
			)

			toast.success(`Приоритет товара №${goodId} успешно изменен`)
		} catch (error) {
			errorHandling(error)
		}
	},

	async getItems({
		categoryId,
		pageNumber = 0,
		pageSize = 21,
		props,
		orderByPrice,
		orderByAlphabet,
	}: IShopGetItems): Promise<IShop> {
		try {
			const { data } = await axiosPrivate.post<IShop>(
				getCatalogUrl(`Прайс-лист/${categoryId}/goods`),
				{ ...props },
				{
					params: {
						pageNumber,
						pageSize,
						orderByPrice,
						orderByAlphabet,
					},
				}
			)
			return data
		} catch (error) {
			errorHandling(error)
			return error
		}
	},

	async getItem(goodId: number): Promise<IProduct> {
		try {
			const { data } = await axiosPrivate.get<IProduct>(
				getCatalogUrl(`${goodId}`)
			)
			return data
		} catch (error) {
			errorHandling(error)
			return error
		}
	},

	async getBest(): Promise<IOrder[]> {
		try {
			const { data } = await axiosPrivate.get<IOrder[]>('/good/best/list')
			return data
		} catch (error) {
			errorHandling(error)
			return error
		}
	},

	async getLike(): Promise<IOrder[]> {
		try {
			const { data } = await axiosPrivate.get<IOrder[]>(
				'/good/maybeyoulike/list'
			)
			return data
		} catch (error) {
			errorHandling(error)
			console.log(error)

			return error
		}
	},
}
