import { getContentType } from 'api/api.helpers'
import { axiosPrivate } from 'api/axios'
import axios from 'axios'
import Cookies from 'js-cookie'
import { toast } from 'react-toastify'

import { IAuthResponse, IRegister } from '@/store/user/user.interface'

import { removeTokensStorage, saveToStorage } from './auth.helper'

export const AuthService = {
	async register(body: IRegister) {
		const response = await axiosPrivate.post<IAuthResponse>(`/register`, body)

		if (response.data.token) {
			saveToStorage(response.data)
		}

		return response
	},
	async login(userName: string, password: string) {
		const response = await axios.post<IAuthResponse>(`/login`, {
			email: userName,
			password,
		})

		if (response.data.token) {
			saveToStorage(response.data)
		}

		return response
	},
	logout() {
		removeTokensStorage()
		localStorage.removeItem('user')
	},
	async getNewTokens() {
		const refreshToken = Cookies.get('refreshToken')
		const accessToken = Cookies.get('accessToken')
		const response = await axiosPrivate.post<IAuthResponse>(
			`/refresh-token`,
			{
				accessToken,
				refreshToken,
			},
			{
				headers: getContentType(),
			}
		)

		if (response.data.token) {
			saveToStorage(response.data)
		}

		return response
	},
	async forgotPassword(email: string, goTo: (url: string) => void) {
		try {
			await axiosPrivate.get(`/forgot_password?Email=${email}`)
			toast.success('Код для восстановления отправлен на почту')
			goTo('/reset-password')
		} catch (e) {
			toast.error(
				e.response.data === `Cant find ${email}`
					? 'Такой почты не существует'
					: 'Ошибка попробуйте позже'
			)
		}
	},
	async resetPassword(
		body: {
			email: string
			token: string
			password: string
		},
		goTo: (url: string) => void
	) {
		try {
			const { data } = await axiosPrivate.post(`/reset_password`, body)
			toast.success('Пароль изменен')
			goTo('/auth')
		} catch (e) {
			toast.error('Ошибка попробуйте позже')
		}
	},
}
