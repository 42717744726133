import { axiosPrivate } from 'api/axios'
import { toast } from 'react-toastify'

import { IOrder } from '@/shared'

const getOrdersUrl = (url: string) => `/orders/${url}`

export const OrdersService = {
	async getAll({
		searchTerm,
		headers,
	}: {
		searchTerm?: string
		headers?: any
	}): Promise<IOrder[]> {
		try {
			const { data } = await axiosPrivate.get<IOrder[]>('/orders', {
				params: searchTerm ? { filter: searchTerm } : {},
				headers,
			})
			return data
		} catch (e: any) {
			toast.error('Ошибка, попробуйте позже')
			return e
		}
	},

	async getById(id: number, headers?: any): Promise<IOrder> {
		try {
			const { data } = await axiosPrivate.get<IOrder>(
				`orders/check_payment?OrderId=${id}`,
				headers
			)
			return data
		} catch (e: any) {
			toast.error('Ошибка, попробуйте позже')
			return e
		}
	},
}
