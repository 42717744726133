import { createAsyncThunk } from '@reduxjs/toolkit'
import { errorCatch } from 'api/api.helpers'
import Cookies from 'js-cookie'
import jwtDecode from 'jwt-decode'
import { toast } from 'react-toastify'

import { AuthService } from '@/services'

import { IAuth, IAuthResponse, IRegister } from './user.interface'

export const reg = createAsyncThunk<IAuthResponse, IRegister>(
	'auth/register',
	async (body, thunkAPI) => {
		try {
			const { data } = await AuthService.register(body)
			toast.success('Пользователь создан, пароль придет на почту')
			const decodeToken: any = jwtDecode(data.token!)

			return {
				...data,
				isAdmin: decodeToken.Role === 'Admin' ? true : false,
			}
		} catch (error: any) {
			toast.error(error.response.data.message)
			return thunkAPI.rejectWithValue(error)
		}
	}
)

export const login = createAsyncThunk<IAuthResponse, IAuth>(
	'auth/login',
	async ({ userName, password }, thunkAPI) => {
		try {
			const { data } = await AuthService.login(userName, password)
			toast.success('Авторизация успешная')
			const decodeToken: any = jwtDecode(data.token!)

			return {
				...data,
				isAdmin: decodeToken.Role === 'Admin' ? true : false,
			}
		} catch (error) {
			console.log(error.response.data.userName)

			toast.error(
				error.response.data.userName !== ''
					? error.response.data.userName
					: error.response.data.password
			)
			return thunkAPI.rejectWithValue(error)
		}
	}
)

export const logout = createAsyncThunk('auth/logout', async () => {
	AuthService.logout()
})

export const checkAuth = createAsyncThunk<IAuthResponse>(
	'auth/check-auth',
	(_, thunkAPI): any => {
		try {
			const accessToken = Cookies.get('accessToken')
			const refreshToken = Cookies.get('refreshToken')
			const decodeToken: any = jwtDecode(accessToken ? accessToken : '')
			return {
				token: accessToken,
				refreshToken: refreshToken,
				isAdmin: decodeToken.Role === 'Admin' ? true : false,
			}
		} catch (error) {
			if (errorCatch(error) === 'jwt expired') {
				toast.error('Your authorizaiton is finished, plz sign in again')
				thunkAPI.dispatch(logout())
			}
			return thunkAPI.rejectWithValue(error)
		}
	}
)
