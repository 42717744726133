import { isAxiosError } from 'axios'
import { toast } from 'react-toastify'

export const errorHandling = (error: unknown) => {
	if (isAxiosError<Record<string, unknown>>(error)) {
		console.log(error.response?.data)
		toast.error('Ошибка, попробуйте позже')
	} else {
		console.error(error)
	}
}
