import cn from 'clsx'
import { FC, memo } from 'react'

import styles from './Button.module.scss'
import { IButton } from './button.types'

export const Button: FC<IButton> = memo(
	({ appearance = 'white', className, children, ...props }): JSX.Element => {
		return (
			<button
				className={cn(styles.button, className, {
					[styles.white]: appearance === 'white',
					[styles.black]: appearance === 'black',
					[styles.blue]: appearance === 'blue',
					[styles.black]: appearance === 'black',
				})}
				{...props}
			>
				{children}
			</button>
		)
	}
)

Button.displayName = 'Button'
