import { FC, ReactNode } from 'react'

import Footer from './Footer/Footer'
import styles from './Layout.module.scss'
import Navbar from './Navbar/Navbar'

const Layout: FC<{ children: ReactNode }> = ({ children }) => {
	return (
		<div className={styles.layout}>
			<Navbar />
			<main className={styles.center}>{children}</main>
			<div id="modal"></div>
			<Footer />
		</div>
	)
}

export default Layout
