import { axiosPrivate } from 'api/axios'
import { toast } from 'react-toastify'

const getPayService = (url: string) => `/orders/${url}`

export const PayService = {
	async pay(orderId: number): Promise<string> {
		try {
			const { data } = await axiosPrivate.post(getPayService('pay'), {
				orderId,
			})

			return data
		} catch (e: any) {
			console.log(e.message)
			toast.error('Ошибка, попробуйте позже')
			return e
		}
	},
}
