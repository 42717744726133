export const modalStyle = {
	overlay: {
		backgroundColor: 'rgba(204, 204, 204, 0.8)',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
		height: '100%',
		overflowY: 'auto',
		padding: '20px',
	},
	content: {
		position: 'relative',
		margin: 'auto',
		border: '1px solid rgb(204, 204, 204)',
		maxWidth: 'max-content',
		width: '90%',
		background: 'rgb(255, 255, 255)',
		borderRadius: '4px',
		outline: 'none',
		padding: '20px',
		inset: 'initial',
	},
}
