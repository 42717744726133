import { FC } from 'react'
import * as MaterialIcons from 'react-icons/md'

import { useRenderClient } from '@/hooks'

import { TypeMaterialIconName } from '@/shared'

export const MaterialIcon: FC<{
	name: TypeMaterialIconName
	size?: number
	className?: string
	onClick?: (props: any) => void
}> = ({ name, size, className, onClick }) => {
	const { isRenderClient } = useRenderClient()

	const IconComponent = MaterialIcons[name]

	if (isRenderClient)
		return (
			<IconComponent size={size} className={className} onClick={onClick} /> || (
				<MaterialIcons.MdDragIndicator />
			)
		)
	else return null
}
