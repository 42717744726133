import cn from 'clsx'
import { FC } from 'react'
import Skeleton, { SkeletonProps } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

export const SkeletonLoader: FC<SkeletonProps> = ({ className, ...rest }) => {
	return (
		<Skeleton
			{...rest}
			baseColor="#747476"
			highlightColor="#939395"
			className={cn('rounded-lg', className)}
		/>
	)
}

SkeletonLoader.displayName = 'skeleton-loader'
