import React, { FC, useCallback, useEffect, useRef, useState } from 'react'

import './MultiRange.scss'

export const MultiRangeInputSlider: FC<{
	min: number
	max: number
	newMin: number
	newMax: number
	onChange: ({ min, max }) => void
}> = ({ min, max, newMin, newMax, onChange }) => {
	const [minVal, setMinVal] = useState(newMin ? newMin : min)
	const [maxVal, setMaxVal] = useState(newMax ? newMax : max)
	const minValRef = useRef<any>(min)
	const maxValRef = useRef<any>(max)
	const range = useRef<any>(null)

	const onChangeHandler = (event, type) => {
		switch (type) {
			case 'left':
				const leftValue = Math.min(Number(event.target.value), maxVal - 1)
				setMinVal(leftValue)
				minValRef.current = leftValue
				break
			case 'right':
				const rightValue = Math.max(Number(event.target.value), minVal + 1)
				setMaxVal(rightValue)
				maxValRef.current = rightValue
				break
			default:
				break
		}
	}

	const getPercent = useCallback(
		(value) => Math.round(((value - min) / (max - min)) * 100),
		[min, max]
	)

	useEffect(() => {
		const minPercent = getPercent(minVal)
		const maxPercent = getPercent(maxValRef.current)

		if (range.current) {
			range.current.style.left = `${minPercent}%!important`
			range.current.style.width = `${maxPercent - minPercent}%!important`
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [minVal])

	useEffect(() => {
		const minPercent = getPercent(minValRef.current)
		const maxPercent = getPercent(maxVal)

		if (range.current) {
			range.current.style.width = `${maxPercent - minPercent}%!important`
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [maxVal])

	useEffect(() => {
		onChange({ min: minVal, max: maxVal })
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [minVal, maxVal])

	return (
		<>
			<div className="slider-container">
				<input
					type="range"
					min={min}
					max={max}
					value={minVal}
					onChange={(event) => onChangeHandler(event, 'left')}
					className="input input-slider-thumb input-range-left"
					style={{
						zIndex: minVal > max - 100 ? 50 : 30,
					}}
				/>
				<input
					type="range"
					min={min}
					max={max}
					value={maxVal}
					className="input input-slider-thumb input-range input-range-right"
					onChange={(event) => onChangeHandler(event, 'right')}
				/>

				<div className="slider-track-container">
					<div className="slider-track" />
					<div ref={range} className="slider-track-value" />
					<div className="slider-track-indicator slider-track-indicator-left">
						{minVal}
					</div>
					<div className="slider-track-indicator slider-track-indicator-right">
						{maxVal}
					</div>
				</div>
			</div>
		</>
	)
}
