import cn from 'clsx'
import { FC } from 'react'

import styles from './Filter.module.scss'
import { IChoice } from './filter.props'

export const ChoiceFilter: FC<IChoice> = ({
	title,
	items,
	checked,
	setChecked,
	handleCheck,
}) => {
	return (
		<div className={cn(styles.filter, styles.choice)}>
			<p className={styles.title}>{title}</p>
			<div className={styles.content}>
				{items
					?.sort((a, b) => {
						if (a.name.trim() < b.name.trim()) {
							return -1
						}
						if (a.name.trim() > b.name.trim()) {
							return 1
						}
						return 0
					})
					?.map((item) => (
						<div className={styles.item} key={item.id}>
							<input
								type="checkbox"
								id={item.id.toString()}
								value={item.id}
								placeholder={item.name}
								checked={checked.includes(item.id.toString())}
								onChange={(event) => handleCheck(event, checked, setChecked)}
							/>
							<label htmlFor={item.id.toString()} key={item.id}>
								{item.name}
							</label>
						</div>
					))}
			</div>
		</div>
	)
}
