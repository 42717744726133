import { axiosPrivate } from 'api/axios'
import { toast } from 'react-toastify'

import { ICart } from '@/shared'

export const BasketService = {
	async getAll(headers?: any): Promise<ICart> {
		try {
			const { data } = await axiosPrivate.get<ICart>('cart/content', headers)
			return data
		} catch (e: any) {
			console.log(e.message)
			//toast.error('Ошибка, попробуйте позже')
			return e
		}
	},

	async deleteById(cartId: number, itemId: number): Promise<number> {
		try {
			const { status } = await axiosPrivate.get(
				`/cart/${cartId}/deletepart/${itemId}`
			)
			return status
		} catch (e: any) {
			console.log(e.message)
			toast.error('Ошибка, попробуйте позже')
			return e
		}
	},

	async newAmountById(
		cartId: number,
		itemId: number,
		amount: number
	): Promise<number> {
		try {
			const { status } = await axiosPrivate.get(
				`/cart/${cartId}/changeamount/${itemId}?newAmount=${amount}`
			)
			toast.success('Кол-во товара изменено')
			return status
		} catch (e: any) {
			console.log(e.message)
			toast.error('Ошибка, попробуйте позже')
			return e
		}
	},

	async addGoods(goodsId: number) {
		try {
			await axiosPrivate.post(
				'/cart/addgoods',
				{
					goodsId,
					amount: 1,
				},
				{
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				}
			)
			toast.success('Добавлено в корзину')
		} catch (e: any) {
			console.log(e.message)
			toast.error('Ошибка, попробуйте позже')
			return e
		}
	},

	async create(data: {
		deliveryAddress: string
		checkArr: Array<number>
	}): Promise<number> {
		try {
			const response = await axiosPrivate.post('/orders/create', {
				//@ts-ignore
				deliveryAddress: data.deliveryAddress.deliveryAddress,
				orderPartsId: data.checkArr,
			})
			return response.data
		} catch (e: any) {
			console.log(e.message)
			toast.error('Ошибка, попробуйте позже')
			return e
		}
	},
}
