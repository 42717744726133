import { axiosPrivate } from 'api/axios'
import { toast } from 'react-toastify'

import { IChangeAddress, IChangeEmail, IChangePassword, IUser } from '@/shared'

export const UserService = {
	async getInfo(headers?: any): Promise<IUser> {
		try {
			const { data } = await axiosPrivate.get<IUser>('/getuserinfo', headers)
			return data
		} catch (e: any) {
			console.log(e)
			toast.error('Ошибка, попробуйте позже')
			return e
		}
	},

	async changeMail(body: IChangeEmail, fun?: () => void) {
		try {
			await axiosPrivate.post('/changeemail', body)
			toast.success('Эл. почта была изменена')
			fun && fun()
		} catch (e: any) {
			console.log(e)
			toast.error('Ошибка сменты почты')
			return e
		}
	},

	async changePassword(body: IChangePassword) {
		return axiosPrivate.post('/changepassword', { ...body })
	},

	async changeAddress(body: IChangeAddress, fun?: () => void) {
		try {
			await axiosPrivate.post('/set_default_delivery_address', body)
			toast.success('Эл. почта была изменена')
			fun && fun()
		} catch (e: any) {
			console.log(e)
			toast.error('Ошибка сменты почты')
			return e
		}
	},
}
