import { PayloadAction, createSlice } from '@reduxjs/toolkit'

type initialStateType = {
	countProducts: null | number
	products: Array<number> | null
}

const initialState: initialStateType = {
	countProducts: null,
	products: null,
}

export const basketSlice = createSlice({
	name: 'basket',
	initialState,
	reducers: {
		setUserId: (state: any, action: PayloadAction<any>) => {
			state.countProducts = action.payload
		},
		setProducts: (state: any, action: PayloadAction<any>) => {
			state.products = action.payload
		},
	},
})

export const { reducer } = basketSlice
export const { setUserId, setProducts } = basketSlice.actions
