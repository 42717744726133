import Link from 'next/link'
import { useRouter } from 'next/router'

import { FC, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'

import { Button, Input } from '@/ui'

import { useActions, useTypedSelector } from '@/hooks'
import { useAuth } from '@/hooks'

import { BasketService } from '@/services'

import { setProducts, setUserId } from '@/store/basket/basket.slice'

import styles from './Navbar.module.scss'

const Navbar: FC = () => {
	const dispatch = useDispatch()
	const { token } = useAuth()
	const { logout } = useActions()
	const { asPath, query } = useRouter()
	const { push } = useRouter()
	const searchRef = useRef<HTMLInputElement>(null)

	const count = useTypedSelector((state) => state.basket.countProducts)

	const fetch = async () => {
		try {
			const response = await BasketService.getAll()
			dispatch(setUserId(response.content.length))
			dispatch(setProducts(response.content.map((i) => i.good.id)))
		} catch (error) {
			console.log(error, 'Navbar')
		}
	}

	useEffect(() => {
		if (token) {
			fetch()
		}
	}, [count, token])

	const handleSearch = () => {
		if (searchRef.current?.value.trim() !== '') {
			if (asPath.split('/')[2]) {
				push({
					pathname: `/catalog/${asPath.split('/')[2]}/1`,
					query: {
						search: searchRef.current?.value.trim(),
					},
				})
			} else {
				push({
					pathname: '/catalog/0/1',
					query: {
						search: searchRef.current?.value.trim(),
					},
				})
			}
		}
	}

	return (
		<header className={styles.header}>
			<input className={styles.menuBtn} type="checkbox" id="menu-btn" />
			<label className={styles.menuIcon} htmlFor="menu-btn">
				<span className={styles.navicon}></span>
			</label>
			<div className={styles.menu}>
				<div>
					<Link href="/">вседлякомпьютеров</Link>
					<Link href="/catalog">каталог</Link>
				</div>
				<div className={styles.search}>
					<Input
						type="search"
						placeholder="Поиск"
						onKeyDown={(e) => e.code === 'Enter' && handleSearch()}
						ref={searchRef}
						defaultValue={query.search ? query.search : ''}
					/>
					<Button appearance="white" onClick={handleSearch}>
						Поиск
					</Button>
				</div>
				{token ? (
					<div>
						<Link href="/basket" className={styles.basket}>
							<span>корзина</span>
							{count ? <div className={styles.info}>{count}</div> : ''}
						</Link>

						<Link href="/my-account">личный кабинет</Link>

						<Link href="/" onClick={logout}>
							Выход
						</Link>
					</div>
				) : (
					<div>
						<Link href={`/auth?redirect=${asPath}`}>вход</Link>

						<Link href="/register">регистрация</Link>
					</div>
				)}
			</div>
		</header>
	)
}

export default Navbar
