import type { AppProps } from 'next/app'

import 'keen-slider/keen-slider.min.css'
import 'react-dadata/dist/react-dadata.css'
import 'react-toastify/dist/ReactToastify.css'

import { TypeComponentAuthFields } from '@/shared'

import '../app/assets/styles/globals.scss'
import MainProvider from '../app/providers/MainProvider'

type TypeAppProps = AppProps & TypeComponentAuthFields

const MyApp = ({ Component, pageProps }: TypeAppProps) => {
	return (
		<MainProvider Component={Component}>
			<Component {...pageProps} />
		</MainProvider>
	)
}

export default MyApp
