import cn from 'clsx'
import { DetailedHTMLProps, FC, HtmlHTMLAttributes } from 'react'

import styles from './Loader.module.scss'

interface LoaderProps
	extends DetailedHTMLProps<
		HtmlHTMLAttributes<HTMLDivElement>,
		HTMLDivElement
	> {
	appearance?: 'center'
}

export const Loader: FC<LoaderProps> = ({ className, appearance, ...rest }) => {
	return (
		<div
			className={cn(styles.loader, className, {
				[styles.center]: appearance === 'center',
			})}
			{...rest}
		>
			<div className={styles.ldsRing}>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
			</div>
		</div>
	)
}

Loader.displayName = 'loader'
