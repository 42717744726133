import cn from 'clsx'
import { FC, useRef } from 'react'

import { Field } from '@/ui'

import styles from './Filter.module.scss'
import { IFromTo } from './filter.props'

export const FromToFilter: FC<IFromTo> = ({ title, min, max, onChange }) => {
	const from = useRef<HTMLInputElement>(null)
	const to = useRef<HTMLInputElement>(null)

	return (
		<div className={cn(styles.filter, styles.fromTo)}>
			<p className={styles.title}>{title}</p>
			<div className={styles.content}>
				<div>
					<Field
						placeholder="от"
						min={min?.toFixed()}
						max={max?.toFixed()}
						type="number"
						defaultValue={min?.toFixed()}
						ref={from}
						onChange={() =>
							onChange({
								min: Number(from.current?.value),
								max: Number(to.current?.value),
							})
						}
					/>
				</div>
				<div>
					<Field
						placeholder="До"
						min={min?.toFixed()}
						max={max?.toFixed()}
						type="number"
						defaultValue={max?.toFixed()}
						ref={to}
						onChange={() =>
							onChange({
								min: Number(from.current?.value),
								max: Number(to.current?.value),
							})
						}
					/>
				</div>
			</div>
		</div>
	)
}
