import Link from 'next/link'

import { FC } from 'react'

import styles from './Footer.module.scss'

const Footer: FC = () => {
	return (
		<footer className={styles.footer}>
			<div>
				<p>ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ {`"ГИПЕРВИЗОР"`}</p>
				<p>ОГРН: 1165658058015</p>
				<p>ИНН/КПП: 5610219152/561001001</p>
				<p>
					Телефон: <Link href="tel:+73532976601">+7(3532) 976-601</Link>
				</p>
				<p>
					Почта: <Link href="mailto:shop3@hvisor.ru">shop3@hvisor.ru</Link>
				</p>
			</div>
			<div className={styles.links}>
				<Link href="">Публичная оферта</Link>
				<Link href="/delivery-info">Информация о доставке</Link>
				<Link href="/exchange-and-return">Обмен и возврат</Link>
			</div>
		</footer>
	)
}

export default Footer
