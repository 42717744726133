import Link from 'next/link'

import { nanoid } from '@reduxjs/toolkit'
import cn from 'clsx'
import React from 'react'

import { MaterialIcon } from '@/ui'

import { DOTS, usePagination } from '@/hooks'

import styles from './Pagination.module.scss'

interface IPagination {
	url: string
	totalCount: number
	siblingCount?: number
	currentPage: number
	pageSize: number
	className: string
	query?: {
		search?: string
		categories?: string[]
		manufacturers?: string[]
		priceFrom?: string
		priceTo?: string
	}
}

export const Pagination = ({
	totalCount,
	siblingCount = 1,
	currentPage,
	pageSize,
	className,
	url,
	query,
}: IPagination) => {
	const paginationRange = usePagination({
		currentPage,
		totalCount,
		siblingCount,
		pageSize,
	})

	if (currentPage === 0 || paginationRange.length === 0) {
		return null
	}

	return (
		<ul className={cn(styles.paginationContainer, { [className]: className })}>
			<li>
				<Link
					href={{ pathname: `${url}/${currentPage - 1}`, query: query }}
					className={cn(styles.paginationItem, {
						[styles.disabled]: currentPage === 1,
					})}
					aria-label="prev"
				>
					<MaterialIcon name="MdKeyboardArrowLeft" size={25} />
				</Link>
			</li>
			{paginationRange!.map((pageNumber) => {
				if (pageNumber === DOTS) {
					return (
						<li
							key={nanoid()}
							className={cn(styles.paginationItem, styles.dots)}
						>
							&#8230;
						</li>
					)
				}

				return (
					<li key={nanoid()}>
						<Link
							href={{ pathname: `${url}/${pageNumber}`, query: query }}
							className={cn(styles.paginationItem, {
								[styles.selected]: pageNumber === currentPage,
							})}
							aria-label={pageNumber.toString()}
						>
							{pageNumber}
						</Link>
					</li>
				)
			})}
			<li>
				<Link
					href={{ pathname: `${url}/${currentPage + 1}`, query: query }}
					className={cn(styles.paginationItem, {
						[styles.disabled]:
							currentPage === paginationRange![paginationRange!.length - 1],
					})}
					aria-label="next"
				>
					<MaterialIcon name="MdKeyboardArrowRight" size={25} />
				</Link>
			</li>
		</ul>
	)
}
