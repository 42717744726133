import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'

import cn from 'clsx'
import { useKeenSlider } from 'keen-slider/react'
import { FC, useState } from 'react'
import { useSelector } from 'react-redux'

import { Button, MaterialIcon, SubHeading } from '@/ui'

import { useAuth, useTypedSelector } from '@/hooks'

import { IGood } from '@/shared'

import { BasketService } from '@/services'

import { getImageUrl } from '@/configs'

const getRandomInt = (min: number, max: number) => {
	min = Math.ceil(min)
	max = Math.floor(max)
	return Math.floor(Math.random() * (max - min + 1)) + min
}

export const Slider: FC<{ body: IGood[]; styles: any }> = ({
	body,
	styles,
}) => {
	const products = useTypedSelector((state) => state.basket.products)
	const router = useRouter()
	const { token } = useAuth()
	const { push } = useRouter()
	const [loaded, setLoaded] = useState(false)
	const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>(
		{
			loop: true,
			// mode: 'free',
			// created() {
			// 	setLoaded(true)
			// },
		},
		[
			(slider) => {
				let timeout: ReturnType<typeof setTimeout>
				let mouseOver = false
				function clearNextTimeout() {
					clearTimeout(timeout)
				}
				function nextTimeout() {
					clearTimeout(timeout)
					if (mouseOver) return
					timeout = setTimeout(
						() => {
							slider.next()
						},
						getRandomInt(5000, 1000)
					)
				}
				slider.on('created', () => {
					slider.container.addEventListener('mouseover', () => {
						mouseOver = true
						clearNextTimeout()
					})
					slider.container.addEventListener('mouseout', () => {
						mouseOver = false
						nextTimeout()
					})
					nextTimeout()
				})
				slider.on('dragStarted', clearNextTimeout)
				slider.on('animationEnded', nextTimeout)
				slider.on('updated', nextTimeout)
			},
		]
	)
	return (
		<div
			ref={sliderRef}
			className={cn(styles.slider, 'keen-slider', 'navigation-wrapper')}
		>
			{body?.length ? (
				body.map((item) => (
					<div
						className={cn(styles.item, 'keen-slider__slide')}
						key={item.id}
						style={{ flexShrink: 0 }}
					>
						<Image
							src={getImageUrl(item.pathToImage[0])}
							alt={item.name}
							width={200}
							height={200}
						/>
						<div className={styles.info}>
							<Link href={`/product/${item.id}`}>
								<SubHeading title={item.name} />
							</Link>
							<p className={styles.price}>
								Цена: <span>{Math.ceil(item.priceCategoryD)} руб</span>
							</p>
							<div className={styles.btn}>
								{products?.includes(item.id) ? (
									<Button
										appearance="black"
										disabled={!token}
										onClick={() => router.push(`/basket`)}
									>
										Уже есть
									</Button>
								) : (
									<Button
										appearance="black"
										disabled={!token}
										onClick={() =>
											BasketService.addGoods(item.id).then(router.reload)
										}
									>
										В корзину
									</Button>
								)}

								<Button
									appearance="black"
									onClick={() => push(`/product/${item.id}`)}
								>
									Подробнее
								</Button>
							</div>
						</div>
					</div>
				))
			) : (
				<div>Товаров нету</div>
			)}
			{instanceRef.current && (
				<>
					<MaterialIcon
						name="MdKeyboardArrowLeft"
						size={30}
						className="arrow arrow--left"
						onClick={(e: any) =>
							e.stopPropagation() || instanceRef.current?.prev()
						}
					/>

					<MaterialIcon
						name="MdKeyboardArrowRight"
						size={30}
						className="arrow arrow--right"
						onClick={(e: any) =>
							e.stopPropagation() || instanceRef.current?.next()
						}
					/>
				</>
			)}
		</div>
	)
}
