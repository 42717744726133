import { axiosPrivate } from 'api/axios'
import { toast } from 'react-toastify'

import { IAdminOrders, IOrderHistoryStatus } from '@/shared'

export const AdminService = {
	async getOrders(searchTerm?: string) {
		try {
			return axiosPrivate.get<IAdminOrders[]>(`/orders/list`, {
				params: searchTerm ? { filter: searchTerm } : {},
			})
		} catch (e: any) {
			console.log(e.message)
			toast.error('Ошибка, попробуйте позже')
			return e
		}
	},

	async changeStatus(id: number, body: string) {
		try {
			return axiosPrivate.patch(
				`/orders/${id}/changeStatus`,
				JSON.stringify(body),
				{
					headers: {
						'Content-Type': 'application/json',
					},
				}
			)
		} catch (e: any) {
			console.log(e.message)
			toast.error('Ошибка, попробуйте позже')
			return e
		}
	},

	async getHistoryStatusById(id: number) {
		try {
			return axiosPrivate.get<IOrderHistoryStatus[]>(`/orders/${id}/history`)
		} catch (e: any) {
			console.log(e.message)
			toast.error('Ошибка, попробуйте позже')
			return e
		}
	},
}
